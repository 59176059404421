.cardOuter{
	margin: 20px 0;
	padding: 0 30px;
}

.cardLink {
	text-decoration: none;
	cursor: pointer;
	border-radius: 21px;
	transition: 0.4s;
  }
  
  .Card {
	border-radius: 21px;
	padding: 0;
	color: #f5f5fd;
	transition: 0.4s;
	border: 0;
	min-height: 482px;
	font-family: "Gopher-Regular";
	text-align: justify;
	color: var(--fontColor-primary);
  }
  
  .Card:hover {
	transform: rotate(1deg);
  }
  
  .cardHeader img {
	width: 100%;
	border-radius: 10px;
	/* box-shadow: #fff 0px 1px 50px 1px;
	box-shadow: #fff 0 8px 15px -8px; */
	opacity: 0.85!important;
	height: 220px;
  }

  
  .card-body p {
	font-size: 16px;
  }
  
  .courseTypeRibbon {
	color: white;
	background-color: #435d16;
	background-color: #181d0fbd;
	border-radius: 0.5rem 0 0 0;
	left: -0.5rem;
	z-index: 10;
  }
  
  .courseTypeRibbon::after {
	position: absolute;
	content: "";
	left: 0;
	top: 1.5rem;
	height: 0.75rem;
	width: 0.5rem;
	background-color: #435d16;
	background-color: #181d0fbd;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 0);
  }

  @media only screen and (max-width:1000px){
	.cardOuter{
		padding: 0 20px;
	}
	
	.Card:hover {
		transform: none;
	  }
  }
  
  /* @media only screen and (max-width:500px){
	.cardOuter{
		padding: 0 10px;
	}
  }
   */

