.left-nav {
	background-color: var(--background_primary);
	display: flex;
	border-radius: 0.5rem 0.5rem 1.5rem 1.5rem;
	position: sticky;
	top: 84px;
	width: 100%;
	max-width: 370px;
}

.left-nav-top {
	flex: 1;
}

.leftNavTopImg {
	position: relative;
}

.leftNavTopImg p {
	position: absolute;
	bottom: 5px;
	left: 0;
	background: #1919f1da;
	color: var(--text_primary);
	padding: 0.3rem 0.8rem;
	border-radius: 30px;
	box-shadow: var(--shadow_primary);
	font-size: 14px;
}

.leftNavTopImg .mentorImage {
	position: absolute;
	width: 1.5rem;
	height: 1.5rem;
	right: 10px;
	bottom: 10px;
	border-radius: 50%;
	cursor: pointer;
}

.left-nav-top ul,
.left-nav-bottom ul {
	list-style: none;
	margin: 0;
	padding: 1rem 2rem;
	padding-left: 0;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.left-nav-top li,
.left-nav-bottom li {
	display: flex;
	align-items: center;
	gap: 0.75rem;
	border-radius: 0.5rem;
	padding: 0.5rem;
	margin-left: 1rem;
}

.left-nav img {
	width: 1.5rem;
	height: 1.5rem;
}

.left-nav a,
.left-nav button {
	text-decoration: none;
	color: #0baae0;
	font-size: 1.20rem;
	margin: 0;
	padding: 0;
	background: none;
}

.left-nav a:hover,
.left-nav button:hover {
	cursor: pointer;
}

.disabled {
	cursor: not-allowed !important;
	opacity: 0.5;
}

.banner {
	width: 100% !important;
	object-fit: cover;
	height: 150px !important;
}

.mob-nav {
	display: flex;
	gap: 0.5rem;
	position: sticky;
	z-index: 106;
	top: 4rem;
	padding: 0 !important;
	transition: 200ms ease-in-out;
}

.mob-nav-hide {
	display: flex;
	gap: 0.5rem;
	position: absolute;
	z-index: 0;
	top: 4rem;
	padding: 0 !important;
	margin-left: -700px !important;
}

.hamburger {
	display: flex;
	gap: 1rem;
	font-size: 2.2rem;
	padding: 0.25rem 0.5rem;
	width: 100%;
	position: sticky;
	top: 0rem;
	/* top: 3rem; */
	z-index: 101;
	cursor: pointer;
	margin-right: 1rem;
	background-color: var(--background_primary);
	align-items: center;
}

.headerTop{
	display: flex;
	flex-direction: column;
}

.headerTop > p:first-child{
	color: var(--fontColor-secondary);
}

.hamburger p {
	font-size: 14px;
	cursor: default;
}

.hamburger button {
	background: none;
	border: none;
	padding: 0;
	margin: 0;
}

.active {
	background-color: var(--background_secondary);
}


.joindedClubs {
	padding: 0.5rem;
	background-color: var(--background_secondary);
	border-radius: 0.5rem 0.5rem 1.5rem 1.5rem;
}

.joinedClubItem {
	display: flex;
	align-items: center;
	gap: 0.75rem;
	border-radius: 0.5rem;
	padding: 0.5rem;
}

.joinedClubItem img {
	border-radius: 50%;
	border: 2px solid var(--border);
}

.activeClub {
	background-color: green;
	height: 10px;
	width: 10px;
	position: absolute;
	border-radius: 50%;
	transform: translate(14px, 2px);
}

.left-nav-top h4 {
	font-size: 22px !important;
}


@media only screen and (max-width:1300px) {

	.left-nav-top h4 {
		font-size: 20px !important;
	}

	.leftNavTopImg p {
		font-size: 12px;
	}

}

@media only screen and (max-width:768px) {

	.left-nav-top ul,
	.left-nav-bottom ul {
		list-style: none;
		margin: 0;
		padding: 0.4rem 2rem !important;
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.left-nav-top li,
	.left-nav-bottom li {
		display: flex;
		align-items: center;
		gap: 0.75rem;
		border-radius: 0.5rem;
		padding: 0.4rem;
		margin-left: -1rem;
	}

	.left-nav {
		height: 100vh;
		border-radius: 0;
	}

	.left-nav-top h4 {
		padding: 15px !important;
		padding-left: 20px !important;
	}

	.leftNavTopImg p {
		font-size: 14px;
	}
}