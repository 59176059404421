.image-flip {
  height: 350px;
}

.mainFlip {
  height: 350px;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  border-radius: 0.25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.mainflip {
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 1s;
  -moz-transition: 1s;
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
}

.frontside {
  background: var(--background_primary);
  position: relative;
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  z-index: 2;
  margin-bottom: 30px;
  cursor: pointer;
  /* box-shadow: 5px 7px 9px -10px rgb(158, 158, 158); */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
}

.backside {
  position: relative;
  bottom: 370px;
  background: var(--background_secondary);
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  /* box-shadow: 5px 7px 9px -4px rgba(194, 57, 57, 0.288); */
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  border-radius: 10px !important;
}

.frontside,
.backside {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 1s;
  -moz-transform-style: preserve-3d;
  -o-transition: 1s;
  -o-transform-style: preserve-3d;
  -ms-transition: 1s;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
}

.Card {
  min-height: 340px !important;
  border-radius: 10px;
  box-shadow: 0px 7px 9px rgba(145, 145, 145, 0.288);
}

.backside a {
  font-size: 18px;
}

.frontside img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.animatedHeading {
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  animation:
    typing 4.5s steps(70, end),
    blink-caret 0.8s step-end infinite;
}

@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

@keyframes blink-caret {
  from, to {
    border-color: transparent
  }
}

@media only screen and (max-width:768px) {
  .animatedHeading {
    animation:
      typing 2s steps(50, end),
      blink-caret 0.4s step-end infinite;
  }
}