.heading {
	padding-bottom: 0.5rem;
}

.upcomingContent {
	padding: 0 1.25rem;
	list-style-type: none;
}

.upcomingContent li {
	border-top: 2px solid var(--background_tertiary);
	padding: 1rem 0 0;
}

.content {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.content > div {
	min-width: calc(75%);
}

.content h5 a {
	text-decoration: none;
	color: var(--fontColor-primary);
}

.icons {
	font-size: 0.85rem;
	color: var(--fontColor-secondary);
	font-size: 0.8rem;
	font-weight: 600;
	color: var(--fontColor-primary);
	margin-bottom: 0.5rem;
}

.icons span {
	padding: 0.25rem 0.5rem 0.25rem 0;
	border-radius: 0.5rem;
	border-radius: 10px;
	padding: 0.25rem 1rem;
	background-color: var(--background_tertiary);
	letter-spacing: calc(1px + 0.1vw);
	width: fit-content;
}

.icons img {
	background-color: white;
	margin-right: 0.5rem;
	border-radius: 0.25rem;
}

.author_timing {
	display: flex;
	/* flex-direction: column;*/
	justify-content: space-between;
}

.author_timing,
.author_timing a {
	text-decoration: none;
	color: var(--fontColor-secondary);
}

.join{
	min-width: auto!important;
}

.join a {
	position: relative;
	left: 60%;
}

@media only screen and (max-width:1300px){
	.join a {
		left :50%;
	}
}

 @media only screen and (max-width:768px){
	.join {
		display: flex;
		align-items: center;
		height: 100%;
	}
}

@media only screen and (max-width:600px){
	.join a {
		position: static;
	}
}
